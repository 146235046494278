import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import "./MangerLogin.css";
import Navtest from "../Navigation/WebView/Navtest";
import Footer from "../Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import End_point from "../../Baseurl";

const ManagerLogin = () => {
  const [patientsList, setPatientList] = useState([]);
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPassword, setManagerPassword] = useState("");
  const [error, setError] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const handleLogin = () => {
    Axios.post(`${End_point}/ManagerLoginmmm`, {
      email: managerEmail,
      password: managerPassword,
    })
      .then((response) => {
        const { data } = response;
        if (data && data.success) {
          if (data.doctorId) {
            navigate("/Admin/Doctor");
            localStorage.setItem("token", data.token);
          } else if (data.managerId) {
            localStorage.setItem("token", data.token);
            navigate("/Admin/Manager");
          } else {
            setError("Invalid role");
          }
        } else {
          setError("Invalid email or password. Please try again.");
        }
      })
      .catch((error) => {
        setError("Invalid email or password. Please try again.");
      });
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(`${text} copied to clipboard!`);
  };

  return (
    <div>
      <Navtest />
      <div>
        <div className="MainBoxes">
          <div className="mainSignUpBodySection">
            <h2>Admin Login</h2>

            <div className="mainSignUpBody">
              <label htmlFor="email">Email</label>
              <input
                placeholder="Enter your email"
                type="email"
                id="email"
                onChange={(e) => {
                  setManagerEmail(e.target.value);
                }}
              />

              <label htmlFor="password">Password</label>
              <input
                placeholder="Enter password"
                type="password"
                id="password"
                onChange={(e) => {
                  setManagerPassword(e.target.value);
                }}
              />

              <button onClick={handleLogin}>Sign In</button>

              {error && <p className="error">{error}</p>}

              <button onClick={handleDropdownToggle}>Demo Accounts!</button>

              {dropdownOpen && (
        <div className="dropdown-container">
          <div className="manager-box">
            <p className="email-text">
              <span className="bold-text">Manager Email:</span>
              <span className="email-address">artclinic@manager.com</span>
              <span className="copy-icon" onClick={() => handleCopy("artclinic@manager.com")}>
                <i className="fas fa-copy"></i>
              </span>
            </p>
            <p className="password-text">
              <span className="bold-text">Manager Password: </span>
              <span className="password"> 54321</span>
              <span className="copy-icon" onClick={() => handleCopy("54321")}>
                <i className="fas fa-copy"></i>
              </span>
            </p>
          </div>
          <div className="doctor-box">
            <p className="email-text">
              <span className="bold-text">Doctor Email:</span>
              <span className="email-address">a.kim@doctor.com</span>
              <span className="copy-icon" onClick={() => handleCopy("a.kim@doctor.com")}>
                <i className="fas fa-copy"></i>
              </span>
            </p>
            <p className="password-text">
              <span className="bold-text">Doctor Password:</span>
              <span className="password">123</span>
              <span className="copy-icon" onClick={() => handleCopy("123")}>
                <i className="fas fa-copy"></i>
              </span>
            </p>
          </div>
        </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManagerLogin;

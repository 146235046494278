import "./App.css";
import Navigation from "./Components/Navigation/Navigation";
import NavWeb from "./Components/Navigation/WebView/NavWeb";

function App() {
  return (
    <div>
      <Navigation />
    </div>
  );
}

export default App;
